@import './variables.scss';

.realfevrJM__allRounds {
    .realfevrJM__allRounds--title {
        text-align: center;

        .title--header {
            font-weight: bold;
            text-align: center;
        }
    }

    .realfevrJM__allRounds--data {
        .data--tableWithPrevNext {
            width: fit-content;
            margin: auto;

            .tableWithPrevNext__table {
                .tableWithPrevNext__table--container {
                    width: fit-content;
                    margin: auto;

                    table {
                        width: 100%;
                        border-collapse: collapse;
                        overflow: hidden;
                        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

                        th,
                        td {
                            padding: 15px;
                            background-color: rgba(255, 255, 255, 0.2);
                            color: #fff;
                        }

                        th {
                            text-align: left;
                        }

                        thead {
                            th {
                                background-color: $button-background-disabled;
                                text-align: center;
                                color: $black;

                                &.round {
                                    text-align: center;
                                }

                                &.team {
                                    text-align: center;
                                }

                                &.coach {
                                }

                                &.points {
                                    text-align: center;
                                }
                            }
                        }

                        tbody {
                            tr {
                                &:hover {
                                    background-color: rgba(255, 255, 255, 0.3);
                                }
                            }

                            td {
                                position: relative;
                                border: 1px solid $button-background-focus;

                                &.round {
                                    text-align: center;
                                }

                                &.team {
                                    text-align: left;
                                }

                                &.coach {
                                }

                                &.points {
                                    text-align: center;
                                }

                                &:hover {
                                    &:before {
                                        content: "";
                                        position: absolute;
                                        left: 0;
                                        right: 0;
                                        top: -9999px;
                                        bottom: -9999px;
                                        background-color: rgba(255, 255, 255, 0.2);
                                        z-index: -1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .jmButtonPrevNext {
                width: 100%;
                display: flex;

                .jmButton {
                    width: 50%;
                    padding: 10px;
                }
            }
        }
    }
}

@media (max-width: $screen-xs){
    .realfevrJM__allRounds {
        font-size: 13px;
        .realfevrJM__allRounds--title {
    
            .title--header {
            }
        }
    
        .realfevrJM__allRounds--data {
            .data--tableWithPrevNext {
    
                .tableWithPrevNext__table {
                    .tableWithPrevNext__table--container {
    
                        table {
    
                            th,
                            td {
                                padding: 11px;
                            }
    
                            th {
                            }
    
                            thead {
                                th {
    
                                    &.round {
                                    }
    
                                    &.team {
                                    }
    
                                    &.coach {
                                        display: none;
                                    }
    
                                    &.points {
                                    }
                                }
                            }
    
                            tbody {
                                tr {
                                    &:hover {
                                    }
                                }
    
                                td {
    
                                    &.round {
                                    }
    
                                    &.team {
                                    }
    
                                    &.coach {
                                        display: none;
                                    }
    
                                    &.points {
                                    }
    
                                    &:hover {
                                        &:before {
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .jmButtonPrevNext {
    
                    .jmButton {
                        button{
                            margin: 15px 0px; 
                        }
                    }
                }

                .buttons__container--next{
                    button{
                        margin: 0px; 
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-xs) and (max-width: $screen-md) {
    .realfevrJM__allRounds {
        font-size: 16px;
        .realfevrJM__allRounds--title {
    
            .title--header {
            }
        }
    
        .realfevrJM__allRounds--data {
            .data--tableWithPrevNext {
    
                .tableWithPrevNext__table {
                    .tableWithPrevNext__table--container {
    
                        table {
    
                            th,
                            td {
                            }
    
                            th {
                            }
    
                            thead {
                                th {
    
                                    &.round {
                                    }
    
                                    &.team {
                                    }
    
                                    &.coach {
                                        display: none;
                                    }
    
                                    &.points {
                                    }
                                }
                            }
    
                            tbody {
                                tr {
                                    &:hover {
                                    }
                                }
    
                                td {
    
                                    &.round {
                                    }
    
                                    &.team {
                                    }
    
                                    &.coach {
                                        display: none;
                                    }
    
                                    &.points {
                                    }
    
                                    &:hover {
                                        &:before {
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .jmButtonPrevNext {
    
                    .jmButton {
                        button{
                        }
                    }
                }
            }
        }
    }
}


