@import './variables.scss';

.realfevrJM__playerList {
    .realfevrJM__playerList--listOfPlayers {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 50px;
        width: 100%;
        margin: 40px 0px;

        /* space between rows, change as needed */
        .listOfPlayers_list {
            cursor: pointer;

            &:focus,
            &:hover {
                background-color: $button-background-focus;
            }

            .listOfPlayers_list--icon {
                width: 40px;
                height: 40px;
                background-image: url('./../img/player.png');
                background-size: contain;
                margin: auto;
            }

            .listOfPlayers_list--name {
                font-weight: bold;
                text-align: center;
            }

            .listOfPlayers_list--username {
                text-align: center;
            }
        }
    }
}

@media (max-width: $screen-xs){
    .realfevrJM__playerList {
        font-size: 13px;

        .realfevrJM__playerList--listOfPlayers {
        grid-template-columns: repeat(2, 1fr);

            .listOfPlayers_list {
    
                &:focus,
                &:hover {
                }
    
                .listOfPlayers_list--icon {
                }
    
                .listOfPlayers_list--name {
                }
    
                .listOfPlayers_list--username {
                }
            }
        }
    }
}
@media (min-width: $screen-xs) and (max-width: $screen-sm){
    .realfevrJM__playerList {
        font-size: 15px;

        .realfevrJM__playerList--listOfPlayers {
        grid-template-columns: repeat(2, 1fr);

            .listOfPlayers_list {
    
                &:focus,
                &:hover {
                }
    
                .listOfPlayers_list--icon {
                }
    
                .listOfPlayers_list--name {
                }
    
                .listOfPlayers_list--username {
                }
            }
        }
    }
}