@import './variables.scss';

.realfevrJM__roundsWinners {
    width: fit-content;
    margin: auto;

    .realfevrJM__roundsWinners--title {
        .title--header {}
    }

    .realfevrJM__roundsWinners--data {
        width: fit-content;
        margin: auto;
        display: flex;

        :nth-child(2) {
            margin: auto 0px auto 50px;
        }

        .data__table {
            .data__table--container {
                width: fit-content;
                margin: auto;

                table {
                    width: 100%;
                    border-collapse: collapse;
                    overflow: hidden;
                    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

                    th,
                    td {
                        padding: 15px;
                        background-color: rgba(255, 255, 255, 0.2);
                        color: #fff;
                    }

                    th {
                        text-align: left;
                    }

                    thead {
                        th {
                            background-color: $button-background-disabled;
                            text-align: center;
                            color: $black;
                        }
                    }

                    tbody {
                        tr {
                            &:hover {
                                background-color: rgba(255, 255, 255, 0.3);
                            }
                        }

                        td {
                            position: relative;
                            border: 1px solid $button-background-focus;

                            &.index0 {
                                text-align: left;
                            }

                            &.index1 {
                                text-align: center;
                            }

                            &:hover {
                                &:before {
                                    content: "";
                                    position: absolute;
                                    left: 0;
                                    right: 0;
                                    top: -9999px;
                                    bottom: -9999px;
                                    background-color: rgba(255, 255, 255, 0.2);
                                    z-index: -1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    .realfevrJM__roundsWinners {
        font-size: 13px;

        .realfevrJM__roundsWinners--title {
            .title--header {}
        }

        .realfevrJM__roundsWinners--data {
            display: inline-block;

            :nth-child(2) {
                margin: 25px auto;
            }

            .data__table {
                .data__table--container {

                    table {

                        th,
                        td {}

                        th {}

                        thead {
                            th {}
                        }

                        tbody {
                            tr {
                                &:hover {}
                            }

                            td {

                                &.round {
                                    text-align: center;
                                }

                                &.team {}

                                &.points {
                                    text-align: center;

                                }

                                &:hover {
                                    &:before {}
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-xs) and (max-width: $screen-lg) {
    .realfevrJM__roundsWinners {

        .realfevrJM__roundsWinners--title {
            .title--header {}
        }

        .realfevrJM__roundsWinners--data {

            :nth-child(2) {}

            .data__table {
                .data__table--container {

                    table {

                        th,
                        td {}

                        th {}

                        thead {
                            th {}
                        }

                        tbody {
                            tr {
                                &:hover {}
                            }

                            td {

                                &.index0 {}

                                &.index1 {}

                                &:hover {
                                    &:before {}
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}