@import './app.scss';


.realfevrJM__home {
    display: flex;
    width: 100vw;
    height: 100vh;

    .realfevrJM__home--listOfPages {
        display: flex;
        width: fit-content;
        margin: auto;

        .homeListContainer {
            width: fit-content;
            text-align: center;
            margin: 50px;

            .homeListImage {
                width: 100px;
                height: 100px;
                margin: auto;
                background-size: contain;
                background-repeat: no-repeat;
            }

            .homeListText {}
        }

        .listOfPages__weeklyRounds {
            .listOfPages__weeklyRounds--image {
                background-image: url('./../img/calendar.png');
            }
        }

        .listOfPages__playerList {
            .listOfPages__playerList--image {
                background-image: url('./../img/user.png');
            }
        }

        .listOfPages__playerWins {
            .listOfPages__playerWins--image {
                background-image: url('./../img/trophy.png');
            }
        }

        .listOfPages__currentRanking {
            .listOfPages__currentRanking--image {
                background-image: url('./../img/ranking.png');
            }
        }

        .listOfPages__login {
            .listOfPages__login--image {
                background-image: url('./../img/login.png');
            }
        }
    }
}

@media (max-width: $screen-xs) {
    .realfevrJM__home {
        .realfevrJM__home--listOfPages {
            display: inline-block;
            margin: 50px auto;
            height: fit-content;

            .homeListContainer {
                margin: 50px auto;

                .homeListImage {
                    width: 50px;
                    height: 50px;
                }

                .homeListText {}
            }

            .listOfPages__weeklyRounds {
                .listOfPages__weeklyRounds--image {}
            }

            .listOfPages__playerList {
                .listOfPages__playerList--image {}
            }

            .listOfPages__playerWins {
                .listOfPages__playerWins--image {}
            }

            .listOfPages__currentRanking {
                .listOfPages__currentRanking--image {}
            }
        }
    }
}

@media (min-width: $screen-xs) and (max-width: $screen-lg) {
    .realfevrJM__home {
        .realfevrJM__home--listOfPages {
            display: inline-block;
            width: 100%;

            .homeListContainer {
                width: 50%;
                margin: 50px 0px;
                display: inline-block;

                .homeListImage {
                    width: 75px;
                    height: 75px;
                }

                .homeListText {}
            }

            .listOfPages__weeklyRounds {
                .listOfPages__weeklyRounds--image {}
            }

            .listOfPages__playerList {
                .listOfPages__playerList--image {}
            }

            .listOfPages__playerWins {
                .listOfPages__playerWins--image {}
            }

            .listOfPages__currentRanking {
                .listOfPages__currentRanking--image {}
            }
        }
    }
}

