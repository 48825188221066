$font-stack: Helvetica, sans-serif;
$white: #ffffff;
$black: #000000;
$grey: #3a414e;
$page-background: $grey;
$button-background: #1ed75f;
$button-background-disabled: #b1cfbb;
$button-background-focus: #227e27;
$button-color: $black;
$button-box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
$button-border: 1px solid rgba(27, 31, 35, .15);
$button-font-family: 1px solid rgba(27, 31, 35, .15);

$screen-xs: 480px;
$screen-sm: 640px;
$screen-md: 768px;
$screen-lg: 1024px;
$screen-xl: 1280px;